import {Container, Typography} from '@mui/material';
import {PropsWithChildren, ReactElement} from 'react';
import jsonData from 'configs/error.json';
import {getRandomInt} from 'utils';

type ErrorProps = {
  error: Error | null;
}

function Error({error, children}: PropsWithChildren<ErrorProps>): ReactElement {
  const {titles, emojies, messages} = jsonData;

  const title = titles[getRandomInt(titles.length)];
  const emoji = emojies[getRandomInt(emojies.length)];
  const message = messages[getRandomInt(messages.length)];

  return (
    <Container maxWidth="sm" className="error-root">
      <div className="error">
        <Typography variant="h4" gutterBottom className="title">{title} {emoji}</Typography>
        <Typography variant="h6" gutterBottom className="message">{message}</Typography>


        <Typography variant="subtitle2" className="technical-message">
        Unable to retrive projects from <a href="https://github.com/bshreddy">github.com/bshreddy</a>.</Typography>
        {children && <Typography variant="subtitle2" className="technical-message">{children}</Typography>}
        <Typography variant="caption" className="error-message">Error: {error?.message ?? 'Unknown Error'}</Typography>
      </div>
    </Container>

  );
}

export default Error;
