import {CircularProgress, Container, Typography} from '@mui/material';
import {PropsWithChildren, ReactElement} from 'react';

function Loading({children}: PropsWithChildren): ReactElement {
  return (
    <Container maxWidth="sm" className="loading-root">
      <div className="loading">
        <CircularProgress color="secondary" size={48} className="spinner" />
        {/* <Typography variant="caption" component="div" className="technical-message">
          Loading projects from <a href="https://github.com/bshreddy">github.com/bshreddy</a>.
        </Typography> */}
        <Typography variant="caption" component="div" className="technical-message">{children}</Typography>

      </div>
    </Container>
  );
}

export default Loading;
