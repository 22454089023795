import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import ReactDOM from 'react-dom/client';
import 'styles/index.scss';
import App from './App';
import {theme} from 'theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </ThemeProvider>

);
