import {ReactElement} from 'react';
import {NavBar} from 'components/NavBar';
import sections from 'configs/sections.json';
import {About} from 'sections/About';
import {Contact} from 'sections/Contact';
import {Hero} from 'sections/Hero';
import {Projects} from 'sections/Projects';
import {Skills} from 'sections/Skills';

const SectionComponents: { [key: string]: ReactElement; }  = {
  hero: <Hero />,
  about: <About />,
  projects: <Projects />,
  skills: <Skills />,
  contact: <Contact />,
};

function App() {
  return (
    <div className="app">
      <NavBar sections={sections.slice(1)}/>

      <>
        {sections.map(section =>
          <section className='section' id={section.id}>
            {SectionComponents[section.id]}
          </section>
        )}
      </>
    </div>
  );
}

export default App;
