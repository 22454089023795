import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography} from '@mui/material';
import {MouseEvent, ReactElement, useState} from 'react';
import {Section} from 'types';

export interface NavBarProps {
  sections: Section[];
}

export function NavBar(props: NavBarProps): ReactElement {
  const {sections} = props;
  const [anchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'dashboard-search-account-menu-mobile';

  return (
    <>
      <AppBar position="fixed" elevation={8} className="navbar">
        <Toolbar>
          <Typography
            className='navbar-title navbar-title-large'
            variant="h6"
            noWrap
          >
            Sai Hemanth Bheemreddy
          </Typography>
          <Typography
            className='navbar-title navbar-title-small'
            variant="h6"
            noWrap
          >
            bshreddy
          </Typography>

          <div className='navbar-grow' />

          <div className='navbar-section-desktop'>
            {sections.map((section) =>
              <Button
                key={section.id}
                variant="text"
                className="navbar-button"
                href={`#${section.id}`}
              >
                {section.name}
              </Button>
            )}
          </div>

          <div className='navbar-section-mobile'>
            <IconButton
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {sections.map((section) =>
          <MenuItem key={section.id} href={`#${section.id}`}>{section.name}</MenuItem>
        )}
      </Menu>
    </>
  );
}
