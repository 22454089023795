import {Typography} from '@mui/material';
import {ReactElement} from 'react';
import RepoCard from './RepoCard';
import {Repository} from 'types';

type SectionProps = {
  title: string;
  subtitle: string | ReactElement;
  repos: Repository[]
}

export function ProjectsSection({title, subtitle, repos}: SectionProps): ReactElement {
  return (
    <div className="projects-section-root">
      <div className="section-head">
        <Typography variant="h4" component="div" className="section-title">{title}</Typography>
        <Typography variant="caption" component="div" className="section-subtitle">{subtitle}</Typography>
      </div>
      <hr />
      <div className="section-content">
        <div className="section-repos">
          {repos.map(repo => {
            return <RepoCard key={repo.id} repo={repo} />;
          })}
        </div>
      </div>
    </div>
  );
}
