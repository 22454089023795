import {Container, Typography} from '@mui/material';
import {ReactElement} from 'react';
import {LoadableSection} from 'components/LoadableSection';
import {AboutData, Dictionary} from 'types';

export function About(): ReactElement {
  const preprocessFunc: Dictionary<any> = {
    addPublicLink: (result: any) => {
      return (
        (result.link.startsWith('http'))
          ? <a href={result.link}>{result.text}</a>
          : <a href={`${process.env.PUBLIC_URL}/${result.link}`}>{result.text}</a>
      );
    },
    jsonPrettyPrint: (result: any) => JSON.stringify(result, null, 2),
    getAge: (result: any) => Math.abs((new Date(Date.now() - (new Date(result)).getTime())).getUTCFullYear() - 1970)
  };

  return (
    <LoadableSection
      dataURL={`${process.env.PUBLIC_URL}/static_configs/about.json`}
      container={(content) => (
        <Container maxWidth="lg" className="about-root">
          <div className="about-head">
            <Typography variant="h4" className="about-title">About Me</Typography>
          </div>
          <hr />
          {content}
        </Container>
      )}
      content={(about: AboutData) => (
        <div className="about-content">
          <Container maxWidth="md" className="about-terminal">
            <div className="title-bar">bshreddy - aboutme</div>
            <div className="body">
              <dl>
                {
                  about
                    .filter(about => (about.hidden ?? false) === false)
                    .map((item, index) => {
                      const result = (item.preprocess) ? preprocessFunc[item.preprocess](item.result) : item.result;

                      return (
                        <>
                          <dd key={`prompt-${index}`}>{item.prompt}</dd>
                          <dt key={`result_attr-${index}`}>
                            {result}
                          </dt>
                        </>
                      );
                    })
                }
              </dl>
            </div>
          </Container>
        </div>
      )}
    />
  );
}
