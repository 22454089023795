import {SvgIcon} from '@mui/material';

function GitHubIcon(props: any) {
  return (
    <SvgIcon viewBox="0 0 1080 1054" {...props}>
      {/* eslint-disable-next-line max-len */}
      <path fill-rule="evenodd" clip-rule="evenodd" d="M540 0C241.65 0 0 241.65 0 540C0 778.95 154.575 980.775 369.225 1052.33C396.225 1057.05 406.35 1040.85 406.35 1026.68C406.35 1013.85 405.675 971.325 405.675 926.1C270 951.075 234.9 893.025 224.1 862.65C218.025 847.125 191.7 799.2 168.75 786.375C149.85 776.25 122.85 751.275 168.075 750.6C210.6 749.925 240.975 789.75 251.1 805.95C299.7 887.625 377.325 864.675 408.375 850.5C413.1 815.4 427.275 791.775 442.8 778.275C322.65 764.775 197.1 718.2 197.1 511.65C197.1 452.925 218.025 404.325 252.45 366.525C247.05 353.025 228.15 297.675 257.85 223.425C257.85 223.425 303.075 209.25 406.35 278.775C449.55 266.625 495.45 260.55 541.35 260.55C587.25 260.55 633.15 266.625 676.35 278.775C779.625 208.575 824.85 223.425 824.85 223.425C854.55 297.675 835.65 353.025 830.25 366.525C864.675 404.325 885.6 452.25 885.6 511.65C885.6 718.875 759.375 764.775 639.225 778.275C658.8 795.15 675.675 827.55 675.675 878.175C675.675 950.4 675 1008.45 675 1026.68C675 1040.85 685.125 1057.73 712.125 1052.33C925.425 980.775 1080 778.275 1080 540C1080 241.65 838.35 0 540 0Z" fill="#1C1C1E"/>
    </SvgIcon>
  );
}

export default GitHubIcon;
