import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Button, Card, IconButton, Link, Typography} from '@mui/material';
import {marked} from 'marked';
import {ReactElement, useState} from 'react';
import LanguagesStrip from './LanguagesStrip';
import GitHubForkIcon from 'assets/icons/GitHubForkIcon';
import GitHubIcon from 'assets/icons/GitHubIcon';
import GitHubLicenseIcon from 'assets/icons/GitHubLicenseIcon';
import GitHubOrgIcon from 'assets/icons/GitHubOrgIcon';
import GitHubRepoIcon from 'assets/icons/GitHubRepoIcon';
import GitHubStarIcon from 'assets/icons/GitHubStarIcon';
import {Repository} from 'types';
import {getIconType, getLanguages} from 'utils';

type RepoCardProps = {
  repo: Repository,
  elevation: number,
  hoverElevation: number
}

const iconsTypeMap: Record<string, ReactElement> = {
  'default': <GitHubRepoIcon />,
  org: <GitHubOrgIcon />,
  fork: <GitHubForkIcon />
};

function RepoCard({repo, elevation, hoverElevation}: RepoCardProps): ReactElement {
  const [cardElevation, setCardElevation] = useState(elevation);

  return (
    <div className="repo-card-root">
      <Card className="repo-card" elevation={cardElevation}
        onMouseOver={() => setCardElevation(hoverElevation)}
        onMouseOut={() => setCardElevation(elevation)}
      >
        <LanguagesStrip data={getLanguages(repo)} />
        <div className="content">
          <div className="content-header">
            <div className="icon-root">{iconsTypeMap[getIconType(repo)]}</div>
            <div className="title-root">
              <Link href={repo.url}>
                <Typography variant="h6" className="title">{repo.name}</Typography>
              </Link>
              {(repo.isFork || repo.isInOrganization) &&
                <Typography variant="caption" className="subtitle">
                  from <Link href={`https://github.com/${repo.nameWithOwner}`}>{repo.nameWithOwner}</Link>
                </Typography>
              }
            </div>
          </div>

          <div className="description">
            {repo.description &&
              <Typography variant="body1" className="description-body">{repo.description}</Typography>
            }

            { (!repo.description && repo.readme?.text) &&
              <div className="description-body">
                <Link href={repo.url}>
                  <Typography variant="caption" className="description-body-heading">README.md</Typography>
                </Link>
                <div
                  className="description-readme"
                  dangerouslySetInnerHTML={{
                    __html: marked.parse(repo.readme.text, {breaks: true, async: false}).toString()
                  }}
                ></div>
              </div>
            }
          </div>

          <div className="topics">
            {repo.repositoryTopics.nodes.map(node => {
              return (
                <div key={node.topic.name} className="topic">
                  <Link href={node.url}>
                    <Typography variant="caption" className="title" color="secondary">{node.topic.name}</Typography>
                  </Link>
                </div>
              );
            })}
          </div>

          <div className="content-footer">
            {/* #TODO: Added contributers and issues open */}
            <div className="left">
              {repo.stargazerCount > 0 &&
                <IconButton size="small" className="footer-buttons" aria-label="github stars">
                  <GitHubStarIcon className="github-star-icon" fontSize="small" color="primary" />
                  <Typography className="icon-text" variant="caption" color="primary">{repo.stargazerCount}</Typography>
                </IconButton>
              }

              {repo.forkCount > 0 &&
                <IconButton size="small" className="footer-buttons" aria-label="github stars">
                  <GitHubForkIcon className="github-fork-icon" fontSize="small" color="primary" />
                  <Typography className="icon-text" variant="caption" color="primary">{repo.forkCount}</Typography>
                </IconButton>
              }
            </div>

            <div className="center">
              {repo.isArchived &&
                <Typography variant="caption" component="div" className="archived-text">Archived</Typography>
              }

              {(!repo.isArchived && repo.licenseInfo) &&
                <Button
                  size="small"
                  className="license-button"
                  aria-label="github stars"
                  href={(repo.licenseInfo.name.toLowerCase() === 'other')
                    ? `${repo.url}/blob/master/LICENSE.md`
                    : repo.licenseInfo!.url}
                  target="_blank"
                >
                  <GitHubLicenseIcon className="license-icon" fontSize="small" color="primary" />
                  <Typography
                    variant="caption"
                    component="div"
                    className="license-text"
                  >
                    {repo.licenseInfo.name}
                  </Typography>
                </Button>
              }
            </div>

            <div className="right">
              <IconButton
                size="small"
                className="footer-buttons"
                aria-label="open github repo"
                href={repo.url}
              >
                <GitHubIcon className="github-icon" color="primary" fontSize="small" />
              </IconButton>

              {repo.homepageUrl &&
                <IconButton
                  size="small"
                  className="footer-buttons"
                  aria-label="open project homepage"
                  href={repo.homepageUrl}
                >
                  <OpenInNewIcon className="link-icon" fontSize="small" />
                </IconButton>
              }
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

RepoCard.defaultProps = {
  elevation: 6,
  hoverElevation: 24
};

export default RepoCard;
