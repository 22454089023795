import axios from 'axios';
import {JSX, ReactElement, ReactNode, useEffect, useState} from 'react';
import Error from 'components/Error';
import Loading from 'components/Loading';

/* eslint-disable no-unused-vars */
export interface LoadableSectionProps<T> {
  dataURL: string;
  content: (data: T) => JSX.Element;
  container: (content: JSX.Element) => JSX.Element;
  loadingChildren?: ReactNode;
  errorChildren?: ReactNode;
}
/* eslint-enable no-unused-vars */

export function LoadableSection<T>(props: LoadableSectionProps<T>): ReactElement {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    (axios.get<T>(props.dataURL)).then((data) => {
      setData(data.data);
    }).catch((err) => {
      setError(err);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return props.container(
    <>
      { loading && <Loading>{props.loadingChildren}</Loading>}
      { (!loading && data) && props.content(data)}
      { (!loading && !data) && <Error error={error}>{props.errorChildren}</Error> }
    </>
  );
}
