import {Container} from '@mui/material';
import {ReactElement} from 'react';
import {LoadableSection} from 'components/LoadableSection';
import {ProjectsSection} from 'components/ProjectsSection';
import {Dictionary, GithubAPIResponse, ProjectsData, Repository} from 'types';

export function Projects(): ReactElement {
  function processData({data}: GithubAPIResponse): ProjectsData {
    data.user.repositories.nodes = data.user.repositories.nodes
      .filter(node =>
        !node.name.startsWith('.')
          && !node.repositoryTopics.nodes.some(topicNode => topicNode.topic.name.startsWith('submodule'))
      );

    const repositories: Dictionary<Repository> =
      data.user.repositories.nodes.reduce((a, x) => ({...a, [x.id]: x}), {});

    return {
      pinned_repos: data.user.pinnedItems.edges.map((pinned_node) => repositories[pinned_node.node.id]),
      other_repos: data.user.repositories.nodes
        .filter((node) => !data.user.pinnedItems.edges.some((pinned_node) => pinned_node.node.id === node.id))
    } as ProjectsData;
  }
  return (
    <LoadableSection
      dataURL={`${process.env.PUBLIC_URL}/static_configs/projects.json`}
      container={(content) => (
        <Container maxWidth="lg" className="projects-list-root">
          {content}
        </Container>
      )}
      content={(githubAPIResponse: GithubAPIResponse) => {
        const projects = processData(githubAPIResponse);

        return (
          <>
            <div className="projects-list-section featured">
              <ProjectsSection
                title="Some Things I've Built"
                subtitle="Featured Projects"
                repos={projects.pinned_repos}
              />
            </div>

            <div className="projects-list-section other">
              <ProjectsSection
                title="Other Projects"
                subtitle={<>View All Repositories on <a href='https://github.com/bshreddy'>My GitHub</a></>}
                repos={projects.other_repos}
              />
            </div>
          </>
        );
      }}
    />
  );
}
