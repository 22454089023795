import {Container, Typography} from '@mui/material';
import {ReactElement} from 'react';
import Category from 'components/Category';
import {LoadableSection} from 'components/LoadableSection';
import {SkillsData} from 'types';

export function Skills(): ReactElement {
  return (
    <LoadableSection
      dataURL={`${process.env.PUBLIC_URL}/static_configs/skills.json`}
      container={(content) => (
        <Container maxWidth="lg" className="skills-root">
          <div className="skills-head">
            <Typography variant="h4" className="skills-title">My Skills</Typography>
            <Typography variant="caption" className="skills-subtitle">Some of my Coding / Technical Skills</Typography>
          </div>
          <hr />
          {content}
        </Container>
      )}
      content={(skills: SkillsData) => (
        <div className="skills-content">
          <div className="skills-catogeries-root">
            {skills.map((category) =>
              <Category category={category} />
            )}
          </div>
        </div>
      )}
    />
  );
}
